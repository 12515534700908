import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import axios from 'axios';
import Layout from "../../components/Layout"
import Header from "../../components/Header"


function View() {
    const navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [content, setContent] = useState({ name: '', description: '' })

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }

        axiosInstance.get(`/projects/view/${id}`)
            .then(function (response) {
				if(response.data.status=='ok')
				{
					if(response.data.message)
					setContent(response.data.message);
				}
				else
				{
					Swal.fire({
						icon: 'error',
						title: response.data.message,
						showConfirmButton: false,
						timer: 1500
					})
				}
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }, [])

	var lineBreak = (str) => {
	return str.split('\n').map(str => <p>{str}</p>);	
	}
	
    return (
        <Layout>
            <Header />
			<div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-info float-right" to="/projects"><span className="fa fa-list"></span> Manager Projects</Link>
						<Link className="btn btn-outline-primary float-end" to={`/projects/edit/${id}`}><span className="fa fa-edit"></span> Edit</Link>
                    </div>
                    <div className="card-body">
                        <p><b>{content.name}</b></p>
                        <div>{lineBreak(content.description)}</div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default View;