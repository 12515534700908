import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../../components/Layout"
import Header from "../../components/Header"

function Add() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate();

	var tmp=localStorage.getItem('user');
	let user = JSON.parse(tmp);

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
    }, [])

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });

    const handleSave = () => {
		if(name=='' || description=='')
		{
			Swal.fire({
				icon: 'error',
				title: 'Please enter all required fields.',
				showConfirmButton: false,
				timer: 1500
			})
		}
		else        
		{
			setIsSaving(true);
			axiosInstance.post('/projects/insert', {
				uid:user.id,
				name: name,
				description: description
			})
				.then(function (response) {
					setIsSaving(false);
					if(response.data.status=='ok')
					{
						Swal.fire({
							icon: 'success',
							title: 'Project saved successfully!',
							showConfirmButton: false,
							timer: 1500
						})
						setName('')
						setDescription('')
					}
					else
					{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
							showConfirmButton: false,
							timer: 1500
						})
					}
				})
				.catch(function (error) {
					Swal.fire({
						icon: 'error',
						title: error,
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false)
				});
		}
    }

    return (
        <Layout>
            <Header />
			<div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-info float-right" to="/projects"><span className="fa fa-list"></span> Manage Projects</Link>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    onChange={(event) => { setName(event.target.value) }}
                                    value={name}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    value={description}
                                    onChange={(event) => { setDescription(event.target.value) }}
                                    className="form-control"
                                    id="description"
                                    rows="3"
                                    name="description"></textarea>
                            </div>
                            <button
                                disabled={isSaving}
                                onClick={handleSave}
                                type="button"
                                className="btn btn-outline-primary mt-3">
                                <span className="fa fa-check"></span> Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Add;