import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../../components/Layout"
import Header from "../../components/Header"

function Edit() {
    const [id, setId] = useState(useParams().id)
    const [name, setName] = useState('');
    const [description, setDescription] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }

        axiosInstance.get(`/projects/view/${id}`)
            .then(function (response) {
                if(response.data.status=='ok')
				{
					let item = response.data.message
					setName(item.name);
					setDescription(item.description);
				}
				else
				{
					Swal.fire({
						icon: 'error',
						title: response.data.message,
						showConfirmButton: false,
						timer: 1500
					})
				}
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: false,
                    timer: 1500
                })
            })

    }, [])


    const handleSave = () => {
		if(name=='' || description=='')
		{
			Swal.fire({
				icon: 'error',
				title: 'Please enter all required fields.',
				showConfirmButton: false,
				timer: 1500
			})
		}
        else
		{
			setIsSaving(true);        
			axiosInstance.put(`/projects/update/${id}`, {
				name: name,
				description: description
			})
				.then(function (response) {
					setIsSaving(false);						
					if(response.data.status=='ok')
					{
						Swal.fire({
							icon: 'success',
							title: 'Project updated successfully!',
							showConfirmButton: false,
							timer: 1500
						})
					}
					else
					{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
							showConfirmButton: false,
							timer: 1500
						})
					}
				})
				.catch(function (error) {
					Swal.fire({
						icon: 'error',
						title: error,
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false)
				});
		}
    }


    return (
        <Layout>
            <Header />
			<div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-info float-right" to="/projects"><span className="fa fa-list"></span> Manager Projects</Link>
						<Link className="btn btn-outline-primary float-end" to={`/projects/view/${id}`}><span className="fa fa-eye"></span> View</Link>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    onChange={(event) => { setName(event.target.value) }}
                                    value={name}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    value={description}
                                    onChange={(event) => { setDescription(event.target.value) }}
                                    className="form-control"
                                    id="description"
                                    rows="10"
                                    name="description"></textarea>
                            </div>
                            <button
                                disabled={isSaving}
                                onClick={handleSave}
                                type="button"
                                className="btn btn-outline-success mt-3">
                                <span className="fa fa-check"></span> Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Edit;