import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../../components/Layout"
import Header from "../../components/Header"

function Add() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate();

	const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

	var tmp=localStorage.getItem('user');
	let user = JSON.parse(tmp);

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
    }, [])

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });

    const handleSave = () => {
        if(name=='' || email=='' || password=='')
		{
            Swal.fire({
                icon: 'error',
                title: 'Please enter all required fields.',
                showConfirmButton: false,
                timer: 1500
            })
		}
        else if(!email.match(isValidEmail))
		{
            Swal.fire({
                icon: 'error',
                title: 'Please enter valid email.',
                showConfirmButton: false,
                timer: 1500
            })
		}
		else        
		{
			setIsSaving(true);
			axiosInstance.post('/registration', {
				name: name,
				email: email,
				password: password,
				password_confirmation: password
			})
				.then(function (response) {
					setIsSaving(false);
					if(response.data.status=='ok')
					{
						Swal.fire({
							icon: 'success',
							title: 'User saved successfully!',
							showConfirmButton: false,
							timer: 1500
						})
						setName('')
						setEmail('')
						setPassword('')
					}
					else
					{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
							showConfirmButton: false,
							timer: 1500
						})
					}
				})
				.catch(function (error) {
					Swal.fire({
						icon: 'error',
						title: error,
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false)
				});
		}
    }

    return (
        <Layout>
            <Header />
			<div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-info float-right" to="/users"><span className="fa fa-users"></span> Manage Users</Link>
                    </div>
                    <div className="card-body">
                        <form>
                                    <div className="form-floating mb-3">
                                        <input 
                                            value={name}
                                            onChange={(event)=>{setName(event.target.value)}}
                                            type="text" 
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Jhon Joe" 
                                        />
                                        <label htmlFor="floatingInput">Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input 
                                        value={email}
                                        onChange={(event)=>{setEmail(event.target.value)}}
                                        type="email" 
                                        className="form-control" 
                                        id="floatingemail" 
                                        placeholder="name@example.com" />
                                        <label htmlFor="floatingemail">Email address</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input 
                                        value={password}
                                        onChange={(event)=>{setPassword(event.target.value)}}
                                        type="password" 
                                        className="form-control" 
                                        id="floatingPassword" 
                                        placeholder="Password" />
                                        <label htmlFor="floatingPassword">Password</label>
                                    </div>
                            <button
                                disabled={isSaving}
                                onClick={handleSave}
                                type="button"
                                className="btn btn-outline-primary mt-3">
                                <span className="fa fa-check"></span> Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Add;