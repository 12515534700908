import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [isSaving, setIsSaving] = useState(false);

	const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    useEffect(() => {
        if (localStorage.getItem('user') && localStorage.getItem('user') != null) {
            navigate("/dashboard");
        }
    }, [])

    const handleSave = () => {
        if(email=='' || password=='')
		{
            Swal.fire({
                icon: 'error',
                title: 'Please enter all required fields.',
                showConfirmButton: false,
                timer: 1500
            })
		}
        else if(!email.match(isValidEmail))
		{
            Swal.fire({
                icon: 'error',
                title: 'Please enter valid email.',
                showConfirmButton: false,
                timer: 1500
            })
		}		
		else
		{
			setIsSaving(true);
	
			axios.post('https://mkghosh.com/nodeapi/login', {
				email: email,
				password: password
			})
				.then(function (response) {
					setIsSaving(false);					
					if(response.data.status=='ok')
					{
						localStorage.setItem("user", JSON.stringify(response.data.message));
						localStorage.setItem("token", response.data.message.id);
						Swal.fire({
							icon: 'success',
							title: 'Login successfully!',
							showConfirmButton: false,
							timer: 1500
						})
						navigate("/dashboard");
						setEmail('')
						setPassword('')
					}
					else
					{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
							showConfirmButton: false,
							timer: 1500
						})
					}
				})
				.catch(function (error) {
					Swal.fire({
						icon: 'error',
						title: error,
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false)
				});
		 		}
    }

    return (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card border-0 shadow rounded-3 my-5">
                            <div className="card-body p-4 p-sm-5">
                                <h5 className="card-title text-center mb-5 fw-light fs-5">Sign In</h5>
                                <form>
                                    <div className="form-floating mb-3">
                                        <input
                                            value={email}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                            type="email"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="name@example.com"
											required="required"
											 />
                                        <label htmlFor="floatingInput">Email address</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            value={password}
                                            onChange={(event) => { setPassword(event.target.value) }}
                                            type="password"
                                            className="form-control"
                                            id="floatingPassword"
                                            placeholder="Password"
											required="required" />
                                        <label htmlFor="floatingPassword">Password</label>
                                    </div>

                                    <div className="d-grid">
                                        <button
                                            disabled={isSaving}
                                            onClick={handleSave}
                                            type="submit"
                                            className="btn btn-primary btn-login text-uppercase fw-bold" >
                                            Sign in
                                        </button>
                                    </div>
                                    <hr className="my-4"></hr>

                                    <div className="d-grid">
                                        <Link className="btn btn-outline-primary btn-login text-uppercase fw-bold" to="/signup">Create new account </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Login;