import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import Select from "react-select";
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../../components/Layout"
import Header from "../../components/Header"

function Edit() {
    const [id, setId] = useState(useParams().id)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [status, setStatus] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }

        axiosInstance.get(`/users/view/${id}`)
            .then(function (response) {
                if(response.data.status=='ok')
				{
					let item = response.data.message
					setName(item.name);
					setEmail(item.email);
					setPassword(item.password);
					setStatus(item.status);
				}
				else
				{
					Swal.fire({
						icon: 'error',
						title: response.data.message,
						showConfirmButton: false,
						timer: 1500
					})
				}
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: false,
                    timer: 1500
                })
            })

    }, [])


    const handleSave = () => {
		if(name=='' || password=='')
		{
			Swal.fire({
				icon: 'error',
				title: 'Please enter all required fields.',
				showConfirmButton: false,
				timer: 1500
			})
		}
        else
		{
			setIsSaving(true);        
			axiosInstance.put(`/users/update/${id}`, {
				name: name,
				password: password,
				status:status
			})
				.then(function (response) {
					setIsSaving(false);						
					if(response.data.status=='ok')
					{
						Swal.fire({
							icon: 'success',
							title: 'User updated successfully!',
							showConfirmButton: false,
							timer: 1500
						})
					}
					else
					{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
							showConfirmButton: false,
							timer: 1500
						})
					}
				})
				.catch(function (error) {
					Swal.fire({
						icon: 'error',
						title: error,
						showConfirmButton: false,
						timer: 1500
					})
					setIsSaving(false)
				});
		}
    }

    return (
        <Layout>
            <Header />
			<div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-info float-right" to="/users"><span className="fa fa-users"></span> Manager Users</Link>
						<Link className="btn btn-outline-primary float-end" to={`/users/view/${id}`}><span className="fa fa-eye"></span> View</Link>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    onChange={(event) => { setName(event.target.value) }}
                                    value={name}
                                    type="text"
                                    className="form-control"
                                    />
                            </div>
                                    <div className="form-group">
									<label htmlFor="name">Password</label>
                                        <input 
                                        value={password}
                                        onChange={(event)=>{setPassword(event.target.value)}}
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Password" />
                                    </div>
                                    <div className="form-group">
									<label htmlFor="name">Status</label>
                                     <select className="form-control" onChange={(event)=>{setStatus(event.target.value)}} value={status}>
									 <option value="1">Active</option>
									 <option value="0">InActive</option>
									 </select>   
                                    </div>

                            <button
                                disabled={isSaving}
                                onClick={handleSave}
                                type="button"
                                className="btn btn-outline-success mt-3">
                                <span className="fa fa-check"></span> Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Edit;