import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./views/Home"
import ProjectIndex from "./views/projects/Index"
import ProjectAdd from "./views/projects/Add"
import ProjectEdit from "./views/projects/Edit"
import ProjectView from "./views/projects/View"

import UserIndex from "./views/users/Index"
import UserAdd from "./views/users/Add"
import UserEdit from "./views/users/Edit"
import UserView from "./views/users/View"

import Login from "./pages/Login"
import Registration from "./pages/Registration"
import Footer from "./components/Footer"

function App() {
  return (
    <Router>
      <div className="content_area">
	  <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/signup" element={<Registration />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/users" element={<UserIndex />} />
        <Route path="/users/add" element={<UserAdd />} />
        <Route path="/users/edit/:id" element={<UserEdit />} />
        <Route path="/users/view/:id" element={<UserView />} />
        <Route exact path="/projects" element={<ProjectIndex />} />
        <Route path="/projects/add" element={<ProjectAdd />} />
        <Route path="/projects/edit/:id" element={<ProjectEdit />} />
        <Route path="/projects/view/:id" element={<ProjectView />} />
      </Routes>
	  </div>
	  <Footer />
    </Router>
  );
}

export default App;