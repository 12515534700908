import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../../components/Layout"
import Header from "../../components/Header"

function Index() {
    const navigate = useNavigate();
    const [contentList, setContentList] = useState([])

	var tmp=localStorage.getItem('user');
	let user = JSON.parse(tmp);
	
	if(user.admin==1)
	var purl='/get_all_data/users/0/id/desc/0/0';
	else
	var purl='/get_all_data_column/users/id/'+user.id+'/id/desc/0/0';

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
        fetchContentList()
    }, [])

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });


    const fetchContentList = () => {
        axiosInstance.get(purl)
            .then(function (response) {
 				if(response.data.status=='ok')
				{
					if(response.data.message)
					setContentList(response.data.message);
				}
				else
				{
					Swal.fire({
						icon: 'error',
						title: response.data.message,
						showConfirmButton: false,
						timer: 1500
					})
				}
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`/users/delete/${id}`)
                    .then(function (response) {
                      if(response.data.status=='ok')
					  {  
						Swal.fire({
                            icon: 'success',
                            title: 'User deleted successfully!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchContentList()
					}
					else
					{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
							showConfirmButton: false,
							timer: 1500
						})
					}
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: error,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }

    return (
        <Layout>
            <Header />
            <div className="container">
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-info" to="/users"><span className="fa fa-users"></span> Manage Users </Link>
						{user.admin?
						(<Link className="btn btn-outline-primary float-end" to="/users/add"><span className="fa fa-plus"></span> Add</Link>)
						:('')
						}
                    </div>
                    <div className="card-body">

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Modified</th>
                                    <th width="240px">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contentList.map((item, key) => {
									var t= new Date(item.created);
									var created=t.getDate()+"-"+(t.getMonth()+1)+"-"+t.getFullYear();
									var t= new Date(item.modified);
									var modified=t.getDate()+"-"+(t.getMonth()+1)+"-"+t.getFullYear();
                                    return (
                                        <tr key={key}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.status?'Active':'Inactive'}</td>
                                            <td>{created}</td>
                                            <td>{modified}</td>
                                            <td>
                                                <Link
                                                    to={`/users/view/${item.id}`}
                                                    className="btn btn-outline-info btn-sm mx-1">
                                                    <span className="fa fa-eye"></span> View
                                                </Link>
                                                <Link
                                                    className="btn btn-outline-success btn-sm mx-1"
                                                    to={`/users/edit/${item.id}`}>
                                                    <span className="fa fa-edit"></span> Edit
                                                </Link>
                                                {item.admin!=1 && item.id!=user.id?(
												<button
                                                    onClick={() => handleDelete(item.id)}
                                                    className="btn btn-outline-danger btn-sm mx-1">
                                                    <span className="fa fa-times"></span> Delete
                                                </button>):('')
								}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Index;