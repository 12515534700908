import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"
import Header from "../components/Header"

function Home() {
    const navigate = useNavigate();
    const [userList, setUserList] = useState([])
    const [projectList, setProjectList] = useState([])

	var tmp=localStorage.getItem('user');
	let user = JSON.parse(tmp);
	
	if(user.admin==1)
	var purl='/get_all_data/projects/0/id/desc/5/0';
	else
	var purl='/get_all_data_column/projects/uid/'+user.id+'/id/desc/5/0';
	
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
        fetchUserList()
        fetchProjectList()
    }, [])

    const axiosInstance = axios.create({
        baseURL: 'https://mkghosh.com/nodeapi/',
    });


    const fetchUserList = () => {
        axiosInstance.get('/get_all_data/users/0/id/desc/5/0')
            .then(function (response) {
 				if(response.data.status=='ok')
				{
					if(response.data.message)
					setUserList(response.data.message);
				}
				else
				{
					Swal.fire({
						icon: 'error',
						title: response.data.message,
						showConfirmButton: false,
						timer: 1500
					})
				}
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const fetchProjectList = () => {
        axiosInstance.get(purl)
            .then(function (response) {
 				if(response.data.status=='ok')
				{
					if(response.data.message)
					setProjectList(response.data.message);
				}
				else
				{
					Swal.fire({
						icon: 'error',
						title: response.data.message,
						showConfirmButton: false,
						timer: 1500
					})
				}
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <Layout>
            <Header />
            <div className="container">
                {user.admin?(
				<div className="card">
                    <div className="card-header">
                        <h3 style={{float:"left"}}>Recent Users</h3>
						<Link className="btn btn-outline-primary float-end" to="/users/add"><span className="fa fa-plus"></span> Add</Link>
                    </div>
                    <div className="card-body">

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList.map((item, key) => {
									var t= new Date(item.created);
									var created=t.getDate()+"-"+(t.getMonth()+1)+"-"+t.getFullYear();
                                    return (
                                        <tr key={key}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.status?'Active':'InActive'}</td>
                                            <td>{created}</td>
                                            <td>
                                                <Link
                                                    to={`/users/view/${item.id}`}
                                                    className="btn btn-outline-info mx-1 btn-sm">
                                                    <span className="fa fa-eye"></span> View
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                ):('')
				}
				<br />
				<div className="card">
                    <div className="card-header">
                        <h3 style={{float:"left"}}>Recent Projects</h3>
						<Link className="btn btn-outline-primary float-end" to="/projects/add"><span className="fa fa-plus"></span> Add</Link>
                    </div>
                    <div className="card-body">

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th width="450px">Description</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectList.map((item, key) => {
									var t= new Date(item.created);
									var created=t.getDate()+"-"+(t.getMonth()+1)+"-"+t.getFullYear();
                                    
									var description=item.description;
                                    if(item.description.length>100)
									description = item.description.substring(0,100)+'...';

									return (
                                        <tr key={key}>
                                            <td>{item.name}</td>
                                            <td>{description}</td>
                                            <td>{created}</td>
                                            <td>
                                                <Link
                                                    to={`/projects/view/${item.id}`}
                                                    className="btn btn-outline-info mx-1 btn-sm">
                                                    <span className="fa fa-eye"></span> View
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Home;